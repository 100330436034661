import React from 'react'
import { ChainId } from '@elkdex/sdk'

import { Row, RowProps } from '../Row'

export const ChainsList = ({ onChainSelect }: { onChainSelect?: RowProps['onChainSelect'] }) => {
  return (
    <>
      <Row onChainSelect={onChainSelect} targetChain={ChainId.ARBITRUM} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.AVALANCHE} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.BINANCE} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.CRONOS} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.ETHEREUM} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.ELAETH} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.FANTOM} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.FUSE} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.XDAI} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.HARMONY} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.HOO} disabled />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.HUOBIECO} disabled />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.IOTEX} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.KUCOIN} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.MOONRIVER} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.OKEX} disabled />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.OPTIMISM} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.MATIC} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.TELOS} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.ROOTSTOCK} />
      <Row onChainSelect={onChainSelect} targetChain={ChainId.Q} />
      {/*<Row onChainSelect={onChainSelect} targetChain={ChainId.KAVA} />*/}
    </>
  )
}
