import { ChainId } from '@elkdex/sdk'

//badge
import arbitrumIconBadge from 'assets/images/chains/badge/arbitrum.svg'
import cronosIconBadge from 'assets/images/chains/badge/cronos.svg'
import elastosIconBadge from 'assets/images/chains/badge/elastos.svg'
import ethereumIconBadge from 'assets/images/chains/badge/ethereum.svg'
import fuseIconBadge from 'assets/images/chains/badge/fuse.svg'
import gnosisIconBadge from 'assets/images/chains/badge/gnosis.svg'
import harmonyIconBadge from 'assets/images/chains/badge/harmony.svg'
import hecoIconBadge from 'assets/images/chains/badge/heco.svg'
import hscIconBadge from 'assets/images/chains/badge/hsc.svg'
import iotexIconBadge from 'assets/images/chains/badge/iotex.png'
import kavaIconBadge from 'assets/images/chains/badge/kava.svg'
import kccIconBadge from 'assets/images/chains/badge/kcc.svg'
import moonriverIconBadge from 'assets/images/chains/badge/moonriver.svg'
import oecIconBadge from 'assets/images/chains/badge/oec.svg'
import optimismIconBadge from 'assets/images/chains/badge/optimism.svg'
import polygonIconBadge from 'assets/images/chains/badge/polygon.svg'
import telosIconBadge from 'assets/images/chains/badge/telos.svg'
import rootstockIconBadge from 'assets/images/chains/badge/rootstock.svg'
import qIconCircle from 'assets/images/chains/circle/q.svg'

// circle
import arbitrumIconCircle from 'assets/images/chains/circle/arbitrum.svg'
import avalancheIconCircle from 'assets/images/chains/circle/avalanche.svg'
import bscIconCircle from 'assets/images/chains/circle/bsc.svg'
import cronosIconCircle from 'assets/images/chains/circle/cronos.svg'
import elastosIconCircle from 'assets/images/chains/circle/elastos.svg'
import ethereumIconCircle from 'assets/images/chains/circle/ethereum.svg'
import fantomIconCircle from 'assets/images/chains/circle/fantom.svg'
import fuseIconCircle from 'assets/images/chains/circle/fuse.svg'
import gnosisIconCircle from 'assets/images/chains/circle/gnosis.svg'
import harmonyIconCircle from 'assets/images/chains/circle/harmony.svg'
import hecoIconCircle from 'assets/images/chains/circle/heco.svg'
import hscIconCircle from 'assets/images/chains/circle/hsc.svg'
import iotexIconCircle from 'assets/images/chains/circle/iotex.png'
import kavaIconCircle from 'assets/images/chains/circle/kava.svg'
import kccIconCircle from 'assets/images/chains/circle/kcc.svg'
import moonriverIconCircle from 'assets/images/chains/circle/moonriver.svg'
import oecIconCircle from 'assets/images/chains/circle/oec.svg'
import optimismIconCircle from 'assets/images/chains/circle/optimism.svg'
import polygonIconCircle from 'assets/images/chains/circle/polygon.svg'
import telosIconCircle from 'assets/images/chains/circle/telos.svg'
import { mapChainIdToTokensChainName, mapChainNameToChainId } from '../sdk'

export const CHAIN_ID_SELECTED_KEY = 'CHAIN_ID_SELECTED'

export interface ChainInfo {
  readonly explorer: string
  readonly infoLink?: string
  readonly label: string
  readonly iconCircle?: string
  readonly iconBadge?: string
  readonly rpcUrls?: string[]
  readonly chainName?: string
  readonly tokenlist: string
  readonly coingeckoId: string
  readonly apollo: {
    clientUrl: string
    blockUrl: string
  }
  readonly nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
}

export const CHAIN_INFO: { [chainId: number]: ChainInfo } = {
  [ChainId.AVALANCHE]: {
    coingeckoId: 'avalanche-2',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/avax.tokenlist.json',
    explorer: 'https://snowtrace.io',
    infoLink: 'https://avax-info.elk.finance/',
    label: 'Avalanche',
    chainName: 'Avalanche C-Chain',
    iconCircle: avalancheIconCircle,
    iconBadge: avalancheIconCircle,
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
    apollo: {
      clientUrl: 'https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-avax',
      blockUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/Hg8oAjoC6kLiFsuZXZX6QYvghnRNq7Y3C8AZaK95g8bF',
    },
  },
  [ChainId.ARBITRUM]: {
    coingeckoId: 'ethereum',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/arbitrum.tokenlist.json',
    explorer: 'https://arbiscan.io',
    infoLink: 'https://arbitrum-info.elk.finance/',
    label: 'Arbitrum',
    chainName: 'Arbitrum One',
    iconCircle: arbitrumIconCircle,
    iconBadge: arbitrumIconBadge,
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    apollo: {
      clientUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/B8TGNwQ8xMoeFCdsv9dPkciRBpEYAy1UxmXDr7nc9fpE',
      blockUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/HMPLhpUMrtrA1wLqwTMDkGx9asWkye2Gc8qySJ1Ld9d',
    },
  },
  [ChainId.ETHEREUM]: {
    coingeckoId: 'ethereum',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/ethereum.tokenlist.json',
    explorer: 'https://etherscan.io',
    infoLink: 'https://eth-info.elk.finance/',
    label: 'Ethereum',
    iconCircle: ethereumIconCircle,
    iconBadge: ethereumIconBadge,
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    apollo: {
      clientUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/59tcH5BPyXj41XZgn1ZYy4pE8iDdzaZpR9MRhmuPW4Lr',
      blockUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/59tcH5BPyXj41XZgn1ZYy4pE8iDdzaZpR9MRhmuPW4Lr',
    },
  },
  [ChainId.OPTIMISM]: {
    coingeckoId: 'ethereum',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/optimism.tokenlist.json',
    explorer: 'https://optimistic.etherscan.io',
    infoLink: 'https://optimism-info.elk.finance/',
    label: 'Optimism',
    chainName: 'Optimism',
    iconCircle: optimismIconCircle,
    iconBadge: optimismIconBadge,
    nativeCurrency: { name: 'Optimistic ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://mainnet.optimism.io'],
    apollo: {
      clientUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/H7UcKWuAqQPqWKcnNLn2Jamy9zE7wVgsLSRQpPwXo2Ag',
      blockUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/4Yor16qPMyJ6P2QLZ7kxsREvemkQZfkuzvvpiYQysCE',
    },
  },
  [ChainId.MATIC]: {
    coingeckoId: 'matic-network',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/matic.tokenlist.json',
    explorer: 'https://polygonscan.com',
    infoLink: 'https://matic-info.elk.finance/',
    label: 'Polygon',
    chainName: 'Polygon Mainnet',
    iconCircle: polygonIconCircle,
    iconBadge: polygonIconBadge,
    rpcUrls: ['https://polygon-rpc.com'],
    nativeCurrency: { name: 'Matic', symbol: 'MATIC', decimals: 18 },
    apollo: {
      clientUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/8jzpT6nnKgmqcdGocys97YWkuqwDbHBxpozsKcxH1KUP',
      blockUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/25qBFAkgzySYXqCW9FMRBcUWYxozss2EaVLqnZ5rrVfi',
    },
  },
  [ChainId.FANTOM]: {
    coingeckoId: 'fantom',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/ftm.tokenlist.json',
    explorer: 'https://ftmscan.com',
    infoLink: 'https://ftm-info.elk.finance/',
    label: 'Fantom',
    chainName: 'Fantom Opera',
    iconCircle: fantomIconCircle,
    iconBadge: fantomIconCircle,
    rpcUrls: ['https://rpc.ftm.tools'],
    nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
    apollo: {
      clientUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/pmqe2dQvH4PK7aaFh4GXrr49wpKRr3GjPCnNEgEb6U2',
      blockUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/GqNRVMymXrjY66USREHphXiN9CVQtudhueFRNbZXbXz8',
    },
  },
  [ChainId.BINANCE]: {
    coingeckoId: 'binancecoin',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/bsc.tokenlist.json',
    explorer: 'https://bscscan.com',
    infoLink: 'https://bsc-info.elk.finance/',
    label: 'BNB Chain',
    chainName: 'Binance Smart Chain Mainnet',
    iconCircle: bscIconCircle,
    iconBadge: bscIconCircle,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed1.binance.org'],
    apollo: {
      clientUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/5tRz9anigEVND48Gx1mUpNNM4YSm3NpzG9XRB8dYAMhb',
      blockUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/BxsXurtXEY7vE1yfiw4rMyK5BEzLYcdX6ZgaGfpLmTPk',
    },
  },
  [ChainId.CRONOS]: {
    coingeckoId: 'crypto-com-chain',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/cronos.tokenlist.json',
    explorer: 'https://cronos.org/explorer',
    infoLink: 'https://cronos-info.elk.finance/',
    label: 'Cronos',
    nativeCurrency: { name: 'Cronos', symbol: 'CRO', decimals: 18 },
    rpcUrls: ['https://evm.cronos.org'],
    chainName: 'Cronos Mainnet Beta',
    iconCircle: cronosIconCircle,
    iconBadge: cronosIconBadge,
    apollo: {
      clientUrl: 'https://cronos-graph.elk.finance/subgraphs/name/elkfinance/elkdex-cronos',
      blockUrl: 'https://cronos-graph.elk.finance/subgraphs/name/elkfinance/cronos-blocks',
    },
  },
  [ChainId.ELAETH]: {
    coingeckoId: 'elastos',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/elastos.tokenlist.json',
    explorer: 'https://eth.elastos.io',
    infoLink: 'https://elastos-info.elk.finance/',
    label: 'Elastos',
    chainName: 'Elastos Smart Chain',
    iconCircle: elastosIconCircle,
    iconBadge: elastosIconBadge,
    nativeCurrency: { name: 'Elastos', symbol: 'ELA', decimals: 18 },
    rpcUrls: ['https://api.elastos.io/eth'],
    apollo: {
      clientUrl: 'https://elastos-graph.elk.finance/subgraphs/name/elkfinance/elkdex-elastos',
      blockUrl: 'https://api.glidefinance.io/subgraphs/name/glide/blocks',
    },
  },
  [ChainId.FUSE]: {
    coingeckoId: 'fuse-network-token',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/fuse.tokenlist.json',
    explorer: 'https://explorer.fuse.io',
    infoLink: 'https://fuse-info.elk.finance/',
    label: 'Fuse',
    chainName: 'Fuse Mainnet',
    iconCircle: fuseIconCircle,
    iconBadge: fuseIconBadge,
    nativeCurrency: { name: 'Fuse', symbol: 'FUSE', decimals: 18 },
    rpcUrls: ['https://rpc.fuse.io'],
    apollo: {
      clientUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/BRuVGMnCGEwCBCHRMTiRD6RuYeUdX5HfYUQj3Jw9rhgY',
      blockUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/6Fozaoy8b3ENLekySykL2pAiDJKNeGtthbwioMabVkU9',
    },
  },
  [ChainId.XDAI]: {
    coingeckoId: 'xdai',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/xdai.tokenlist.json',
    explorer: 'https://blockscout.com/xdai/mainnet',
    infoLink: 'https://xdai-info.elk.finance/',
    label: 'Gnosis',
    chainName: 'Gnosis Chain (formerly xDai)',
    iconCircle: gnosisIconCircle,
    iconBadge: gnosisIconBadge,
    nativeCurrency: { name: 'Gnosis', symbol: 'xDAI', decimals: 18 },
    rpcUrls: ['https://rpc.gnosischain.com'],
    apollo: {
      clientUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/kD9njskfB9xv7gDnsU2sz4X4sXfEimBv8xMJ6votFND',
      blockUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/HTGgWawRHBKr6tDe6umPoVNkYDoUpBEsJSGtX6A7N8MG',
    },
  },
  [ChainId.HARMONY]: {
    coingeckoId: 'harmony',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/harmony.tokenlist.json',
    explorer: 'https://explorer.harmony.one',
    infoLink: 'https://harmony-info.elk.finance/',
    label: 'Harmony',
    chainName: 'Harmony Mainnet Shard 0',
    iconCircle: harmonyIconCircle,
    iconBadge: harmonyIconBadge,
    nativeCurrency: { name: 'Harmony', symbol: 'ONE', decimals: 18 },
    rpcUrls: ['https://api.harmony.one'],
    apollo: {
      clientUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/4k2aMMzp2YhHSSDaCrmaXfYpqP8mn7UJVALjv7mYRTAs',
      blockUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/GRZo3ppBA36zc1wnBJgaGHn3ht9iBZn2MjsNeWdHjyPJ',
    },
  },
  [ChainId.HOO]: {
    coingeckoId: 'hoo-token',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/hoo.tokenlist.json',
    explorer: 'https://hooscan.com',
    infoLink: 'https://hoo-info.elk.finance/',
    label: 'HSC',
    chainName: 'Hoo Mainnet RPC',
    iconCircle: hscIconCircle,
    iconBadge: hscIconBadge,
    nativeCurrency: { name: 'HSC', symbol: 'HOO', decimals: 18 },
    rpcUrls: ['https://http-mainnet.hoosmartchain.com'],
    apollo: {
      clientUrl: 'https://hoo-graph.elk.finance/subgraphs/name/elkfinance/elkdex-hoo',
      blockUrl: 'https://hoo-graph.elk.finance/subgraphs/name/elkfinance/hoo-blocks',
    },
  },
  [ChainId.HUOBIECO]: {
    coingeckoId: 'huobi-token',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/heco.tokenlist.json',
    explorer: 'https://hecoinfo.com',
    infoLink: 'https://heco-info.elk.finance/',
    label: 'HECO',
    chainName: 'Huobi ECO Chain Mainnet',
    iconCircle: hecoIconCircle,
    iconBadge: hecoIconBadge,
    nativeCurrency: { name: 'HT', symbol: 'HT', decimals: 18 },
    rpcUrls: ['https://http-mainnet-node.huobichain.com'],
    apollo: {
      clientUrl: 'https://heco-graph.elk.finance/subgraphs/name/elkfinance/elkdex-heco',
      blockUrl: 'https://heco-lite-graph.mdex.cc/subgraphs/name/mdex-blocks',
    },
  },
  [ChainId.IOTEX]: {
    coingeckoId: 'iotex',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/iotex.tokenlist.json',
    explorer: 'https://iotexscan.io',
    infoLink: 'https://iotex-info.elk.finance/',
    label: 'IoTeX',
    chainName: 'IoTeX Network Mainnet',
    iconCircle: iotexIconCircle,
    iconBadge: iotexIconBadge,
    nativeCurrency: { name: 'IOTX', symbol: 'IOTX', decimals: 18 },
    rpcUrls: ['https://babel-api.mainnet.iotex.io'],
    apollo: {
      clientUrl: 'https://iotex-graph.elk.finance/subgraphs/name/elkfinance/elkdex-iotex',
      blockUrl: 'https://iotex-graph.elk.finance/subgraphs/name/elkfinance/iotex-blocks',
    },
  },
  [ChainId.KUCOIN]: {
    coingeckoId: 'kucoin-shares',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/kcc.tokenlist.json',
    explorer: 'https://explorer.kcc.io',
    infoLink: 'https://kcc-info.elk.finance/',
    label: 'KCC',
    chainName: 'KCC Mainnet',
    iconCircle: kccIconCircle,
    iconBadge: kccIconBadge,
    nativeCurrency: { name: 'KCC', symbol: 'KCS', decimals: 18 },
    rpcUrls: ['https://rpc-mainnet.kcc.network'],
    apollo: {
      clientUrl: 'https://kcc-graph.elk.finance/subgraphs/name/elkfinance/elkdex-kcc',
      blockUrl: 'https://kcc-graph.elk.finance/subgraphs/name/elkfinance/kcc-blocks',
    },
  },
  [ChainId.MOONRIVER]: {
    coingeckoId: 'moonriver',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/moonriver.tokenlist.json',
    explorer: 'https://moonriver.moonscan.io',
    infoLink: 'https://moonriver-info.elk.finance/',
    label: 'Moonriver',
    chainName: 'Moonriver',
    iconCircle: moonriverIconCircle,
    iconBadge: moonriverIconBadge,
    nativeCurrency: { name: 'Moonriver', symbol: 'MOVR', decimals: 18 },
    rpcUrls: ['https://rpc.api.moonriver.moonbeam.network'],
    apollo: {
      clientUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/J1iQfZdRWPeZNj3nAkcKgMT2B67TFU1yqEiWo4Kz4WZD',
      blockUrl:
        'https://gateway-arbitrum.network.thegraph.com/api/67092eeba991b3e62aa878a9059e7cbb/subgraphs/id/Ejy1RbSuBR8TRnRUWDXKqK4rZUrvV2htqcQ4jBcYxhim',
    },
  },
  [ChainId.OKEX]: {
    coingeckoId: 'oec-token',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/okex.tokenlist.json',
    explorer: 'https://www.oklink.com',
    infoLink: 'https://okex-info.elk.finance/',
    label: 'OKC',
    chainName: 'OKExChain Mainnet',
    iconCircle: oecIconCircle,
    iconBadge: oecIconBadge,
    nativeCurrency: { name: 'OKEx', symbol: 'OKT', decimals: 18 },
    rpcUrls: ['https://exchainrpc.okex.org'],
    apollo: {
      clientUrl: 'https://okex-graph.elk.finance/subgraphs/name/elkfinance/elkdex-okex',
      blockUrl: 'https://okex-graph.elk.finance/subgraphs/name/elkfinance/okex-blocks',
    },
  },
  [ChainId.TELOS]: {
    coingeckoId: 'telos',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/telos.tokenlist.json',
    explorer: 'https://www.teloscan.io',
    infoLink: 'https://telos-info.elk.finance/',
    label: 'Telos',
    iconCircle: telosIconCircle,
    iconBadge: telosIconBadge,
    chainName: 'Telos EVM Mainnet',
    nativeCurrency: { name: 'Telos', symbol: 'TLOS', decimals: 18 },
    rpcUrls: ['https://mainnet.telos.net/evm'],
    apollo: {
      clientUrl: 'https://telos-graph2.elk.finance/subgraphs/name/elkfinance/elkdex-telos',
      blockUrl: 'https://telos-graph.elk.finance/subgraphs/name/elkfinance/telos-blocks',
    },
  },
  [ChainId.KAVA]: {
    coingeckoId: 'kava',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/kava.tokenlist.json',
    explorer: 'https://explorer.kava.io',
    // infoLink: 'https://kava-info.elk.finance/',
    label: 'Kava',
    chainName: 'Kava EVM Co-Chain',
    iconCircle: kavaIconCircle,
    iconBadge: kavaIconBadge,
    nativeCurrency: { name: 'Kava', symbol: 'KAVA', decimals: 18 },
    rpcUrls: ['https://evm.kava.io'],
    apollo: {
      clientUrl: 'https://kava-graph.elk.finance/subgraphs/name/elkfinance/elkdex-kava',
      blockUrl: 'https://kava-graph.elk.finance/subgraphs/name/elkfinance/kava-blocks',
    },
  },
  [ChainId.Q]: {
    coingeckoId: 'q-protocol',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/q.tokenlist.json',
    explorer: 'https://explorer.q.org',
    label: 'Q',
    chainName: 'Q Mainnet',
    iconCircle: qIconCircle,
    iconBadge: qIconCircle,
    nativeCurrency: { name: 'QGOV', symbol: 'QGOV', decimals: 18 },
    apollo: {
      clientUrl: 'http://162.244.80.145:8000/subgraphs/name/elkfinance-q/',
      blockUrl: 'http://31.14.41.35:8000/subgraphs/name/elkfinance-q-blocks/',
    },
  },
  [ChainId.ROOTSTOCK]: {
    coingeckoId: 'rootstock',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/rootstock.tokenlist.json',
    explorer: 'https://explorer.rootstock.io',
    label: 'Rootstock',
    chainName: 'Rootstock Mainnet',
    iconCircle: rootstockIconBadge,
    iconBadge: rootstockIconBadge,
    nativeCurrency: { name: 'RBTC', symbol: 'RBTC', decimals: 18 },
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/87715/elkfinance-rootstock/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/87715/elkfinance-rootstock-blocks/version/latest',
    },
  },
}

if (!localStorage?.getItem(CHAIN_ID_SELECTED_KEY)) {
  localStorage?.setItem(CHAIN_ID_SELECTED_KEY, mapChainIdToTokensChainName[ChainId.AVALANCHE])
}

export const CHAIN_NAME = localStorage?.getItem(CHAIN_ID_SELECTED_KEY)
export const CHAIN_ID = mapChainNameToChainId(CHAIN_NAME)

export const CHAIN_INFO_SELECTED = CHAIN_INFO[CHAIN_ID]
